/* eslint-disable @typescript-eslint/space-before-function-paren */
import axios from 'axios'
import {
  ArticleListModel,
  AttributeListModel,
  AttributeModel,
  CardModel,
  CategoryModel,
  CategoryStatus,
  CategoryType,
  FormAssociatedModel,
  FormListModel,
  FormModel,
  NewsListModel,
  SearchScopeType,
  SurveyAnswerModel,
  SurveyIdentifierType,
  SurveyListModel,
  SurveyQuestionModel,
  SurveyTemplateModel,
  UserAdminModel
} from '../entities'
import { IAttributeProps } from 'components/Fields/AttributeFields/AttributeFieldList'

const url = process.env.REACT_APP_API_URL

export const api = axios.create({
  baseURL: url
})

export const getToken = (): string | null => {
  const token = localStorage.getItem('token')
  return token
}

// --------------------
// SESSION APIs
// --------------------
export async function createSession(
  name: string,
  username: string
): Promise<{ token: string, user: any }> {
  const userData = {
    name,
    username
  }
  const response = await api.post('/session', userData)
  return response.data
}

// --------------------
// FORM APIs
// --------------------
export async function getFormByUuid(id: string | number): Promise<FormModel> {
  const response = await api.get(`/form/${id}`, { headers: { Authorization: getToken() } })
  return response.data
}

export async function getFormByTitle(title: string): Promise<FormModel[]> {
  const response = await api.get(`/form/title/${title}`, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

type GetPaginateProps = {
  page?: number
  limit?: number
}

export async function getForms({ page = 0, limit = 5 }: GetPaginateProps): Promise<FormListModel> {
  const response = await api.get(`/form?limit=${limit}&page=${page}&order=DESC`, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

export type CreateFormProps = {
  title: string
  isEnable: boolean
  associatedCard: { id: number | string, title: string } | null
  attributes: Array<{ id: number | string, title: string } | IAttributeProps>
  categoryId: string | null
  type: CategoryType | null
  group: string | null
  categoryName: string | null
  openingStatus: CategoryStatus | null
}

export async function createForm(payload: CreateFormProps): Promise<FormModel> {
  const response = await api.post('/form', payload, { headers: { Authorization: getToken() } })
  return response.data
}

export async function editForm(
  id: string | number,
  dto: CreateFormProps | FormModel
): Promise<FormModel> {
  const response = await api.put(`/form/${id}`, dto, { headers: { Authorization: getToken() } })
  return response.data
}

export async function deleteForm(id: string | number): Promise<any> {
  const response = await api.delete(`/form/${id}`, { headers: { Authorization: getToken() } })
  return response.data
}

// --------------------
// CARD APIs
// --------------------
export async function getCards(): Promise<CardModel[]> {
  const response = await api.get('/card', { headers: { Authorization: getToken() } })
  return response.data
}

export type CreateCardProps = {
  name: string
  icon: string
  color: null | string
  systemTypes: null
  birthOrder: number
  isEnable: boolean
  parent: null | { id: number | string }
  children: []
}

export async function createCard(payload: CreateCardProps): Promise<CardModel> {
  const response = await api.post('/card', payload, { headers: { Authorization: getToken() } })
  return response.data
}

export async function editCard(payload: CardModel): Promise<CardModel> {
  const response = await api.put(`/card/${payload.id}`, payload, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

export async function deleteCard(id: string | number): Promise<any> {
  const response = await api.delete(`/card/${id}`, { headers: { Authorization: getToken() } })
  return response.data
}

export async function updateCardListOrder(payload: CardModel[]): Promise<void> {
  const response = await api.put('/card/order', payload, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

// --------------------
// MANAGEMENT ADMIN APIs
// --------------------
export async function createNewAdmin(name: string, userLogin: string): Promise<any> {
  const userData = {
    name,
    userLogin: userLogin.toLowerCase()
  }
  const response = await api.post('/user', userData, { headers: { Authorization: getToken() } })
  return response.data
}

export async function getAdminList(): Promise<UserAdminModel[]> {
  const response = await api.get('/user', { headers: { Authorization: getToken() } })
  return response.data
}

export async function EditAdmin(
  name: string,
  userLogin: string,
  id: string | number
): Promise<any> {
  const userData = {
    name,
    userLogin: userLogin.toLowerCase()
  }
  const response = await api.put(`/user/${id}`, userData, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

export async function DeleteAdmin(id: string | number): Promise<any> {
  const response = await api.delete(`/user/${id}`, { headers: { Authorization: getToken() } })
  return response.data
}
// --------------------
// NEWS ADMIN APIs
// --------------------
export async function createNews(
  description: string,
  urlPicture: string,
  weight: number
): Promise<any> {
  const newsData = {
    description,
    url_picture: urlPicture,
    weight
  }
  const response = await api.post('/news', newsData, { headers: { Authorization: getToken() } })
  return response.data
}

export async function getNewsList({ page = 0, limit = 0 }: GetPaginateProps): Promise<NewsListModel> {
  const response = await api.get(`/news?limit=${limit}&page=${page}&order=DESC`, { headers: { Authorization: getToken() } })
  return response.data
}
export async function deleteNews(id: string | number): Promise<any> {
  const response = await api.delete(`/news/${id}`, { headers: { Authorization: getToken() } })
  return response.data
}

export type EditNewsProps = {
  description: string
  urlPicture: string
  id: string | number
  weight: number
  isEnable: boolean
}

export async function editNews({
  description,
  urlPicture,
  id,
  weight,
  isEnable
}: EditNewsProps): Promise<any> {
  const newsData = {
    description,
    url_picture: urlPicture,
    weight,
    isEnable
  }
  const response = await api.put(`/news/${id}`, newsData, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

type NewsListType = {
  id: string | number
  weight: number
}

export async function updateNewsListWeight(newsList: NewsListType[]): Promise<any> {
  const response = await api.post('/news/weight', newsList, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

// --------------------
// ARTICLE/ FAQs ADMIN APIs
// --------------------
type CreateArticleProps = {
  title: string
  content: string
  files: string[]
  formsAssociated: FormAssociatedModel[]
}

export async function createArticle(dto: CreateArticleProps): Promise<any> {
  const response = await api.post('/article', dto, { headers: { Authorization: getToken() } })
  return response.data
}

export async function getArticleList(page: number): Promise<ArticleListModel> {
  const response = await api.get(`/article?limit=${5}&page=${page}&order=DESC`, {
    headers: { Authorization: getToken() }
  })
  return response.data
}
export async function deleteArticle(id: string | number): Promise<any> {
  const response = await api.delete(`/article/${id}`, { headers: { Authorization: getToken() } })
  return response.data
}

export async function editArticle(id: string | number, dto: CreateArticleProps): Promise<any> {
  const response = await api.put(`/article/${id}`, dto, { headers: { Authorization: getToken() } })
  return response.data
}

export type ArticleFeedbackProps = {
  userName: string
  userLogin: string
  formId: string | number
  articleId: string | number
  isUtil: boolean
}

export async function articleFeedback(dto: ArticleFeedbackProps): Promise<any> {
  const response = await api.post('/article/answer', dto, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

// --------------------
// CATEGORY API
// --------------------
export async function getCategories(): Promise<CategoryModel[]> {
  const response = await api.get('/categorie', { headers: { Authorization: getToken() } })
  return response.data
}

// --------------------
// ATTRIBUTE API
// --------------------
// export async function getAllAttributes (): Promise<AttributeModel[]> {
//   const response = await api.get('/attributes', { headers: { Authorization: getToken() } })
//   return response.data
// }

export async function getAllAttributes(): Promise<AttributeListModel> {
  const response = await api.get('/attributes', { headers: { Authorization: getToken() } })
  return response.data
}

export type PaginationProps = {
  page?: number
  limit?: number
  order?: 'DESC' | 'ASC'
}
export async function getAttributesWithPagination({
  page = 0,
  limit = 5,
  order = 'DESC'
}: PaginationProps): Promise<AttributeListModel> {
  const response = await api.get(`/attributes?limit=${limit}&page=${page}&order=${order}`, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

export async function getAttributeByID(id: string | number): Promise<AttributeModel> {
  const response = await api.get(`/attributes/${id}`, { headers: { Authorization: getToken() } })
  return response.data
}

export async function getAttributeByQuestion(question: string): Promise<AttributeModel[]> {
  const response = await api.get(`/attributes?question=${question}`, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

export async function deleteAttributeByID(id: string | number): Promise<AttributeModel> {
  const response = await api.delete(`/attributes/${id}`, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

export async function editAttribute(payload: AttributeModel): Promise<AttributeModel> {
  const response = await api.put(`/attributes/${payload.id}`, payload, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

export async function createAttribute(payload: IAttributeProps): Promise<AttributeModel> {
  const response = await api.post('/attributes', payload, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

// --------------------
// SEARCH API
// --------------------
type GetSearchDataProps = {
  query: string
  scope: SearchScopeType
}

export type GetSearchDataResponse = {
  id: number | string
  createdAt: string
  updatedAt: string
  label: string
  description: string
  scope: SearchScopeType
  searchTerms: string[]
}
export async function getSearchData({
  scope,
  query
}: GetSearchDataProps): Promise<GetSearchDataResponse[]> {
  const response = await api.get(`/searchable?scope=${scope}&query=${query}`, {
    headers: { Authorization: getToken() }
  })

  return response.data
}

// --------------------
// SURVEY API
// --------------------
export async function getSurveyDataByIdentifier(
  identifier: SurveyIdentifierType,
  enable: boolean = true
): Promise<SurveyTemplateModel> {
  const response = await api.get(`/survey/templates/${identifier}?enable=${enable.toString()}`, {
    headers: { Authorization: getToken() }
  })

  return response.data
}

export async function saveSurveyAnswers(dto: SurveyAnswerModel): Promise<void> {
  const payload = {
    userName: dto.userName,
    userLogin: dto.userLogin,
    userEmail: dto.userEmail,
    ticketId: dto.ticketId,
    surveyAnswers: dto.surveyAnswers,
    ticketHandle: dto.ticketHandle
  }
  const response = await api.post(`/survey/responses/${dto.templateId}`, payload, {
    headers: { Authorization: getToken() }
  })

  return response.data
}

export async function getSurveysAnswered(
  ids: string,
  userLogin: string
): Promise<Record<string, boolean>> {
  const response = await api.get(`/survey/responses/${userLogin}?${ids}`, {
    headers: { Authorization: getToken() }
  })

  return response.data
}

export async function getSurveyList({
  page = 0,
  limit = 5,
  order = 'DESC'
}: PaginationProps): Promise<SurveyListModel> {
  const response = await api.get(`/survey/templates?limit=${limit}&page=${page}&order=${order}`, {
    headers: { Authorization: getToken() }
  })

  return response.data
}

export type CreateSurveyProps = Omit<SurveyTemplateModel, 'id' | 'surveyQuestions'> & {
  surveyQuestions: Array<Omit<SurveyQuestionModel, 'id'>>
}

export async function createSurvey(payload: CreateSurveyProps): Promise<SurveyTemplateModel> {
  const response = await api.post('/survey/templates', payload, {
    headers: { Authorization: getToken() }
  })

  return response.data
}

export async function editSurvey(payload: SurveyTemplateModel): Promise<SurveyTemplateModel> {
  const response = await api.put(`/survey/templates/${payload.id}`, payload, {
    headers: { Authorization: getToken() }
  })

  return response.data
}

export async function deleteSurvey(id: string | number): Promise<SurveyTemplateModel> {
  const response = await api.delete(`/survey/templates/${id}`, {
    headers: { Authorization: getToken() }
  })

  return response.data
}
