import { useFormContext } from 'react-hook-form'
import { AttributeModel } from '../../entities'

import { ErrorMessage, FormInputNumber } from './styles'

type Props = {
  attribute: AttributeModel
}

const NumberField = ({ attribute }: Props) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  const isRequired = attribute.required ?? false
  return (
    <FormInputNumber>
      <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
      <input
        type="number"
        placeholder={attribute.placeholder ?? ''}
        {...register(attribute.id.toString(), { required: isRequired })}
      />
      {errors[attribute.id.toString()]?.type === 'required' && (
        <ErrorMessage>* Campo obrigatório</ErrorMessage>
      )}
    </FormInputNumber>
  )
}

export default NumberField
