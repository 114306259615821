import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { ModalContent } from 'components'
import { INews } from '../../entities'
import { editNews, EditNewsProps } from 'services/api'

import { ModalBodyWarning } from 'components/Modal/styles'
import { useGetNews } from 'hooks'

type Props = {
  toggleModal: () => void
  updateData: () => void
  newsSelected: INews
}

const EnableNewsModal = ({ toggleModal, newsSelected, updateData }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const { newsActive, getNews } = useGetNews()
  const MAX_ACTIVE_NEWS = 15
  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const dto: EditNewsProps = {
        description: newsSelected.description,
        urlPicture: newsSelected.imageUrl,
        id: newsSelected.id,
        weight: newsSelected.weight,
        isEnable: !newsSelected.isEnable
      }

      if (!newsSelected.isEnable && newsActive.length === MAX_ACTIVE_NEWS) {
        toast.error('Ops 😥, você atingiu o limite de 15 notícias')
        throw new Error()
      }
      const response = await editNews(dto)

      if (!response) {
        throw new Error()
      }

      updateData()
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥,  ocorreu um erro ao editar o formulário')
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  useEffect(() => {
    getNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalContent
      title={newsSelected?.isEnable ? 'Desativar notícia' : 'Ativar notícia'}
      handleCloseModal={toggleModal}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      btnText="Confirmar"
      btnColor="#ea580c"
    >
      <ModalBodyWarning>
        <div className="question">
          <span className="icon">
            <FontAwesomeIcon icon={faExclamation} />
          </span>
          {newsSelected?.isEnable ? (
            <p>Deseja desativar o destaque: </p>
          ) : (
            <p>Deseja ativar o destaque: </p>
          )}
        </div>
        <div className="text" dangerouslySetInnerHTML={{ __html: newsSelected.description }}></div>
        {newsSelected?.isEnable ? (
          <span className="warning-msg">
            <FontAwesomeIcon icon={faTriangleExclamation} />
            <p>Ao desativar este destaque não poderá ser visualizado!</p>
          </span>
        ) : (
          <span className="warning-msg">
            <FontAwesomeIcon icon={faTriangleExclamation} />
            <p>Ao ativar este destaque será visualizado!</p>
          </span>
        )}
      </ModalBodyWarning>
    </ModalContent>
  )
}

export default EnableNewsModal
