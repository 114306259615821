/* eslint-disable @typescript-eslint/indent */
import { useMemo, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ModalContent } from 'components'
import { EditorContentField, ImageFileField } from 'components/Fields'
import { deleteFilePublic } from 'services/fileManager'
import { editNews } from 'services/api'
import { INews } from '../../entities'
import { getUrlFile, reactQuillContainerParams, validations } from './utils'

import { ModalBodyForm } from 'components/Modal/styles'
import { ModalWrapper } from 'pages/Admin/styles'
import PreviewNewsModal from './PreviewNewsModal'
import NumberField from 'components/Fields/NumberField'

interface IFormInput {
  image: File | string
  content: string
  weight: number
}

type Props = {
  toggleModal: () => void
  newsSelected: INews
  getNewsPagination: () => void
}

const EditNewsModal = ({ toggleModal, newsSelected, getNewsPagination }: Props) => {
  const defaultValues = {
    content: newsSelected?.description,
    image: newsSelected?.imageUrl,
    weight: newsSelected?.weight
  }
  const methods = useForm<IFormInput>({ defaultValues })
  const [isLoading, setIsLoading] = useState(false)
  const refPreviewModal = useRef<HTMLDialogElement>(null)
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)
  const [previewContent, setPreviewContent] = useState<null | Pick<
    INews,
    'imageUrlData' | 'description' | 'weight'
  >>(null)

  const modules = useMemo(
    () => ({
      toolbar: {
        container: reactQuillContainerParams
      }
    }),
    []
  )

  const togglePreviewModal = async () => {
    if (refPreviewModal.current) {
      if (isOpenPreviewModal) {
        setIsOpenPreviewModal(false)
        refPreviewModal?.current.close()
        return
      }
      let image = methods.getValues('image')
      if (methods.getValues('image') instanceof File) {
        image = URL.createObjectURL(methods.getValues('image') as File)
      }

      const data = {
        imageUrlData: image as string,
        description: methods.getValues('content'),
        weight: methods.getValues('weight')
      }

      setPreviewContent(data)
      setIsOpenPreviewModal(true)
      refPreviewModal?.current.showModal()
    }
  }

  const checkDataChanges = (data: IFormInput): boolean => {
    const isEqualImage = data.image === newsSelected.imageUrl
    const isEqualContent =
      JSON.stringify(data.content) === JSON.stringify(newsSelected.description)
    const isEqualWeight = data.weight === newsSelected.weight

    return !(isEqualImage && isEqualContent && isEqualWeight)
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    const result = data.content.replace(/(<([^>]+)>)/ig, '')
    const hasChanges = checkDataChanges(data)
    if (!hasChanges) {
      toast.warn('Nada para editar!')
      toggleModal()
      return
    }
    if (!data.image) {
      methods.setError('image', { type: 'error', message: '* Campo obrigatório' })
      return
    }

    if (result.length > 130) {
      methods.setError('content', { type: 'error', message: '* Máximo 130 caracteres' })
      return
    }

    // Empty description
    if (data.content === '<p><br></p>') {
      methods.setError('content', { type: 'error', message: '* Campo obrigatório' })
      return
    }

    setIsLoading(true)
    let urlImage: string = newsSelected?.imageUrl
    if (data.image instanceof File) {
      urlImage = await getUrlFile(data.image)
    }
    try {
      const response = await editNews({
        description: data.content,
        urlPicture: urlImage,
        id: newsSelected.id,
        weight: data.weight,
        isEnable: newsSelected.isEnable
      })

      if (!response) {
        throw new Error()
      }
      if (urlImage !== newsSelected.imageUrl) {
        await deleteFilePublic(newsSelected.imageUrl)
      }
      setIsLoading(false)
      getNewsPagination()
    } catch (error: any) {
      setIsLoading(false)
      await deleteFilePublic(urlImage)
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao editar o destaque')
    } finally {
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Editar destaque"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      // handleOpenModal={togglePreviewModal}
      // btn2Text="Visualizar"
      >
        <ModalBodyForm>
          <div className="news-image-adm">
            <ImageFileField
              attribute={{
                id: 'image',
                question: '',
                optionsSelectElement: [],
                required: true,
                isHiddenToVip: false
              }}
              imageUrlData={newsSelected.imageUrlData}
            />
          </div>
          <EditorContentField
            modulesEditor={modules}
            validations={validations}
            fieldName="content"
            label="Edite o texto"
          />
          <div>
            <label htmlFor="weight">Ordem de exibição</label>
            <NumberField
              attribute={{
                id: 'weight',
                question: '',
                optionsSelectElement: [],
                required: true,
                isHiddenToVip: false
              }}
            />
          </div>
        </ModalBodyForm>
      </ModalContent>
      <ModalWrapper ref={refPreviewModal} $width={'40%'}>
        {isOpenPreviewModal && (
          <PreviewNewsModal news={previewContent} toggleModal={togglePreviewModal} />
        )}
      </ModalWrapper>
    </FormProvider>
  )
}

export default EditNewsModal
